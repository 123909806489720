<template>
  <div class="co-flex-col">
    <v-card
      class="overflow-y-auto overflow-x-hidden white rouned px-8 py-6"
      flat
      height="610"
    >
      <div
        class="co-flex-row co-justify-center co-items-center mb-4"
      >
        <v-btn
          :loading="btnMore.loading"
          plain
          :style="loadStates[btnMore.state]"
        >
          {{ loadTexts[btnMore.state] || '查看更多信息' }}
        </v-btn>
      </div>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="co-flex-col"
      >
        <template v-if="item.toId === fromUser.userId">
          <message-im-content
            :data="item"
          />
        </template>
        <template v-else>
          <message-im-content
            :data="item"
            back-color="blue"
            fore-color="white"
            reversed
          />
        </template>
      </div>
    </v-card>

    <v-card
      class="rounded pa-4 mt-4"
      flat
      tile
      color="white"
    >
      <div class="co-flex-row co-items-center co-w-full">
        <v-avatar
          size="56"
        >
          <v-img
            :src="fromUser.avatar"
            cover
          />
        </v-avatar>
        <v-text-field
          v-model="msg"
          :label="msgLabel"
          outlined
          hide-details
          class="mx-4"
        />
        <v-btn
          :loading="btnSend.loading"
          color="primary"
          height="56"
          :width="btnSend.width"
          @click="onSend"
        >
          {{ btnSend.text }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'
  import socket from '@/api/co.socket'

  import dayjs from 'dayjs'
  import relativeTime from 'dayjs/plugin/relativeTime'
  import 'dayjs/locale/zh-cn'

  dayjs.locale('zh-cn')
  dayjs.extend(relativeTime)

  export default {
    components: {
      MessageImContent: () => import('@/pages/sections/my/MessageImContent.vue'),
    },
    props: {
      to: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        toUser: {},
        fromUser: {},
        msg: '',
        msgLabel: '回复',
        btnSend: {
          loading: false,
          width: 90,
          text: '发送'
        },
        btnMore: {
          loading: false,
          state: 0
        },
        params: null,
        items: [],
        loadStates: [
          'color: #2196F3;',
          'color: #2196F3;',
          'color: #a0aec0;',
          'color: #E53E3E;',
          'color: #a0aec0;'
        ],
        loadTexts: [
          '查看更多信息',
          '正在加载中...',
          '全部加载完成',
          '加载失败，重新刷新',
          '暂时没有信息'
        ],
        toOptions: {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        }
      }
    },
    watch: {
      to: {
        handler (val) {
          this.toUser = Object.assign({}, val)
          this.msgLabel = `回复${val.username}`
          this.configParams()
          this.loadRecords()
        },
        immediate: true
      }
    },
    created () {
      this.fromUser = user.info.get()
    },
    methods: {
      goTo (msgId) {
        const me = this
        setTimeout(() => {
          const target = `#msg_${msgId}`
          // console.log({ target })
          me.$vuetify.goTo(target, me.toOptions)
        }, 500)
      },
      configParams () {
        const me = this
        const executing = function () {

        }

        const executed = function (res) {
          // console.log('items: %o', me.items)
          if (res.status) {
            const cnt = me.items.length
            if (cnt > 0) {
              const last = me.items[cnt - 1]
              me.goTo(last.msgId)
            }
          }
        }

        this.params = web.imMsg.getParams({
          chatUserId: this.toUser.userId,
          type: 'chat',
          reversed: true,
          caches: this.items,
          executing,
          executed
        })
      },
      loadRecords (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
      convertMessageItem (data) {
        const result = Object.assign({}, data)
        result.to = this.toUser
        result.from = this.fromUser

        const nowTime = dayjs()
        const crtTime = dayjs(data.createTime)
        const ms = nowTime.diff(crtTime)
        result.hintTime = api.comm.humanize(ms)
        result.shortTime = dayjs(data.createTime).format('MM-DD HH:mm')

        return result
      },
      addMessageToChat (data) {
        this.items.push(data)
      },
      onSend () {
        const me = this
        const content = api.comm.trim(this.msg)
        if (!content) {
          return
        }

        const data = {
          content,
          contentType: web.comm.MessageTypes.CHAT,
          type: web.comm.MessageTypes.CHAT,
          fromId: this.fromUser.userId,
          toId: this.to.userId
        }

        const executing = function () {
          me.btnSend.loading = true
        }

        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            const data = res.data
            const dst = me.convertMessageItem(data)
            data.createTime = null
            data.updateTime = null
            data.state = 'ready_send'
            const message = JSON.stringify(data)
            socket.main.send(message).then((_ev) => {
              me.addMessageToChat(dst)
              me.msg = ''
              me.btnSend.loading = false
            }).catch(_err => {
              me.btnSend.loading = false
            })
          } else {
            me.btnSend.loading = false
          }
        }

        web.imMsg.create({
          data,
          executing,
          executed
        })
      }
    }
  }
</script>

<style>
  .msg-chat {
    display: flex;
    flex-direction: column;
    height: 610px;
  }
</style>
